import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ChatMessageService } from '@injectables/services/chat/chat-message.service';
import { AlertService } from '@injectables/services/alert/alert.service';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ProjectActionDialogComponent } from '../../project/project-action-dialog/project-action-dialog.component';
import { ERROR } from '@shared/constants/firebase';
import { ChatService } from '@injectables/services/chat/chat.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Company } from '@shared/models/company.model';
import { InviteState, Member, MemberRole, Message, MessageType, Project } from 'domain-entities';
import { MESSAGE_STATE } from '@shared/models/message.model';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectUserId } from '@store/selectors/app.selectors';
import { selectFileUploadPercentage } from '@store/selectors/file-upload.selectors';
import {
	getMembersOfProject,
	getRoleOfProjectMember,
} from '@shared/functions/project/project.functions';
import { selectHasCurrentUserProfileLimits } from '@store/selectors/profile-limits.selectors';
import { ProfileLimitKey } from '@shared/models/profile-limit-key.enum';
import { selectAuthorOfMessage } from '@store/selectors/projects.selectors';
import { BaseFileService } from '@injectables/services/base-file.service';
import { checkMessageType } from '../utils/utils';
import { is48HoursPassed } from '@shared/shared.utils';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';

@Component({
	selector: 'app-chat-message',
	templateUrl: './chat-message.component.html',
	styleUrls: ['../chat.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent implements OnInit, OnDestroy, OnChanges {
	private markMessagesUsingCloudFunction: boolean;
	@ViewChild('messageElement') messageElement: ElementRef;
	MAX_TAGS_LENGTH = 30;
	checkMessageType = checkMessageType;
	@Input()
	message: Message;
	@Input()
	pdfExportActive: boolean;
	@Input()
	isPreviewAvailable: Observable<boolean>;
	@Input()
	exportList = [];
	@Input()
	exportAll = false;
	@Input()
	project: Project;
	@Input()
	company: Observable<Company>;
	@Input()
	file: any;
	@Input()
	files: [];
	@Input()
	messageDates = [];
	@Input()
	messageSend = false;
	@Output()
	goToTagging = new EventEmitter<Message>();
	@Output()
	shareMessageToFile = new EventEmitter<Message>();
	@Output()
	gotToParent = new EventEmitter<Message>();
	@Output()
	getFile = new EventEmitter<
		| Message
		| {
				id: string;
				messageType: string;
				authorId: string;
				content: string;
				duration?: number;
		  }
	>();
	@Output()
	showImage = new EventEmitter<Message>();
	@Output()
	showVideo = new EventEmitter<Message>();
	@Output()
	showPdf = new EventEmitter<Message>();
	@Output()
	replyTo = new EventEmitter<Message>();
	@Output()
	messageSelected = new EventEmitter<{messageId: string; isSelected: boolean}>();
	MessageType = MessageType;
	MESSAGE_STATE = MESSAGE_STATE;
	userId$ = this.store.select(selectUserId);
	authorOfReplyMessage$: Observable<Member>;
	userHasTaggingLimit$ = this.store.select(selectHasCurrentUserProfileLimits, {
		limit: ProfileLimitKey.TAGGING,
	});
	destroy$: Subject<boolean> = new Subject();
	messageState$: Observable<MESSAGE_STATE>;

	constructor(
		public readonly chatMessageService: ChatMessageService,
		private readonly alertService: AlertService,
		private readonly datePipe: DatePipe,
		private readonly authService: AuthService,
		private readonly chatService: ChatService,
		private readonly deleteDialog: MatDialog,
		private readonly store: Store<AppState>,
		private readonly baseFileService: BaseFileService,
		private readonly remoteConfig: RemoteConfig,
		private readonly errorHandlerService: ErrorHandlerService,
	) {}

	get messageState(): MESSAGE_STATE {
		const message = this.message;

		let messageState: MESSAGE_STATE;
		if (this.markMessagesUsingCloudFunction) {
			messageState = this.handleNewMessageState(message.status);
		} else {
			messageState = this.handleOldMessageState(message);
		}

		return messageState || MESSAGE_STATE.DEFAULT;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.message) {
			this.messageState$ = of(this.messageState);
		}
	}

	async ngOnInit(): Promise<void> {
		this.authorOfReplyMessage$ = this.store.select(selectAuthorOfMessage, {
			projectId: this.project.id,
			authorId: this.message?.parent?.authorId,
		});

		this.markMessageWithStatus('received');

		this.markMessagesUsingCloudFunction = await this.remoteConfig.getBooleanAsync(
			'feature_mark_messages_using_cloud_function',
		);
		this.messageState$ = of(this.messageState);
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	getFileName(message: Message): string {
		if (message.fileName) {
			return message.fileName;
		}
		return message.content ? message.content.replace(message.id, '') : message.content;
	}

	onSelectToExport($event, message): void {
		this.messageSelected.emit({
			messageId: message.id,
			isSelected: $event.checked
		});
	}

	canEditTag(msg): Observable<boolean> {
		return this.company.pipe(
			map((company: Company) => {
				if (this.isMessageAuthor(msg)) {
					return true;
				}
				if (this.project.company !== company.id) {
					return false;
				}
				return company.isEmployee(this.authService.currentUserId());
			}),
		);
	}

	isMessageAuthor(message: Message): boolean {
		return message.authorId === this.authService.currentUserId();
	}

	userCanDeleteMessage(message: Message): boolean {
		const userRole = getRoleOfProjectMember(this.authService.currentUserId(), this.project);
		if (userRole === MemberRole.EMPLOYEE || userRole === MemberRole.EXTERNAL) {
			if (!this.isMessageAuthor(message)) {
				return false;
			}
			// Info:: basic/paid/external employees can only delete message if its within 2 days
			return !is48HoursPassed(message.timestamp);
		} else {
			return true;
		}
	}

	deleteMessageApproval(message): void {
		const dialogRef = this.deleteDialog.open(ProjectActionDialogComponent, {
			data: {
				title: 'chat.dialog.delete_possible.title',
				content: 'chat.dialog.delete_possible.approval',
				isInfoDialog: false,
			},
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				if (result) {
					this.chatService
						.deleteMessage(this.project.id, message)
						.then(() => {
							this.alertService.showAlert('chat.message.deleteSuccess');
						})
						.catch((error) => {
							this.alertService.showAlert(ERROR + error.code);
						});
				}
			});
	}

	download(message: Message): void {
		if (message.content === MessageType.NOTSET) {
			return;
		}
		this.chatService
			.getFile(this.project.id, message, null, false)
			.then((result) => {
				this.baseFileService.downloadFile(result, message.fileName ?? message.content);
			})
			.catch(() => this.alertService.showAlert(ERROR, { duration: 2000 }));
	}

	showTagsMessage(message: Message): string[] | void {
		if (!message.tags) {
			return;
		}

		const displayedTags: Array<string> = [];
		const messageTags = message.tags;
		let tagCount = 0;

		for (const tag of messageTags) {
			tagCount += tag.length;

			if (tagCount < this.MAX_TAGS_LENGTH) {
				displayedTags.push(tag);
			} else {
				displayedTags.push('...');
				break;
			}
		}
		return displayedTags;
	}

	getTime(message: Message): string {
		try {
			const date = new Date(message.timestamp * 1000);
			return this.datePipe.transform(date, 'HH:mm');
		} catch (error) {
			return '';
		}
	}

	isDocumentProcessed(message: Message): boolean {
		return this.chatMessageService.getFileType(message.content).toUpperCase() !== 'NOTSET';
	}

	/**
	 * @deprecated The method should not be used it will be replaced by the new logic
	 */
	isMessageReadByAllUsers(message: Message): boolean {
		if (!this.project) {
			return false;
		}

		const currentUser = this.authService.currentUserId();
		const noOfOpenedMembers = Object.keys(this.project.membersLastOpened).filter(
			(lastOpenedMemberId) =>
				this.project.membersLastOpened[lastOpenedMemberId] > message.timestamp &&
				lastOpenedMemberId !== currentUser,
		).length;
		const noOfMembers = getMembersOfProject(this.project).filter(
			(member) => member.inviteState === InviteState.ACCEPTED && member.id !== currentUser,
		).length;
		return noOfMembers <= noOfOpenedMembers;
	}

	getFileUploadPercentage(id: string): Observable<string> {
		return this.store.select(selectFileUploadPercentage(id)).pipe(
			map((percentage) => {
				if (!percentage) {
					return '';
				}
				return `${percentage} %`;
			}),
		);
	}

	replyToMessage(message: Message): void {
		this.replyTo.emit(message);
	}

	goToParentMessage(message: Message): void {
		this.gotToParent.emit(message);
	}

	getBackgroundImageStyleUrl(file: string): string {
		return `url('${file}')`;
	}

	ngAfterViewInit() {
		this.observeMessageVisibility();
	}

	private observeMessageVisibility(): void {
		if (!this.messageElement) {
			return;
		}
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.5,
		};

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) {
					return;
				}
				this.markMessageWithStatus('read');
			});
		}, options);

		observer.observe(this.messageElement.nativeElement);
	}

	private isMessageStatusStoredLocally(status: Message['status']): boolean {
		return sessionStorage.getItem(this.message.id + ':' + status) === 'true';
	}

	private storeMessageStatusLocally(status: string) {
		//store only in session in case something goes wrong to retry later
		sessionStorage.setItem(this.message.id + ':' + status, 'true');
	}

	private handleNewMessageState(status: Message['status']): MESSAGE_STATE {
		if (status === 'read') {
			return MESSAGE_STATE.READ_BY_ALL_USERS;
		} else if (status === 'received') {
			return MESSAGE_STATE.PROCESSED;
		}
	}

	private handleOldMessageState(message: Message): MESSAGE_STATE {
		if (this.isMessageReadByAllUsers(message)) {
			return MESSAGE_STATE.READ_BY_ALL_USERS;
		} else if (message.processed) {
			return MESSAGE_STATE.PROCESSED;
		}
	}

	private markMessageWithStatus(status: Message['status']) {
		if (
			this.isMessageAuthor(this.message) ||
			this.message.status === status ||
			this.isMessageStatusStoredLocally(status)
		) {
			return;
		}

		this.chatMessageService
			.setMessageStatus(this.message.projectId || this.project.id, [this.message.id], status)
			.subscribe({
				next: (_response) => {
					this.storeMessageStatusLocally(status);
				},
				error: (error) => {
					this.errorHandlerService.handleError(error);
				},
			});
	}
	
}
