import { NgModule } from '@angular/core';
import { Route, Router, RouterModule, Routes } from '@angular/router';
import { mobileRoute } from '@shared/mobile/mobile.route';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { OwnerGuard } from '@injectables/guards/owner/owner.guard';
import {
	redirectLoggedInToDefault,
	redirectUnauthenticatedToLogin,
} from '@injectables/services/route-guard-functions';
import { RemoteConfigGuard } from '@injectables/guards/remote-config/remote-config.guard';
import { ProfileLimitsGuard } from '@injectables/guards/profile-limits/profile-limits.guard';
import { ProfileLimitKey } from '@shared/models/profile-limit-key.enum';
import { registerRoute } from '@modules/features/register/register.route';
import { passwordForgottenRoute } from '@modules/features/password-forgotten/password-forgotten.route';
import { workRoute } from '@work/work.route';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '@env/environment';
import { CustomWebComponent } from '../shared/components/custom-web-component/custom-web.component';
import { CompanyProjectsLoadedResolver } from '@injectables/resolvers/company-projects-loaded/company-projects-loaded.resolver';
import { InvitationsComponent } from '../shared/components/invitations/invitations.component';
import { MemberRole } from 'domain-entities';
import { MemberRoleGuard } from '@injectables/guards/member-role/member-role.guard';
import { loginRoute } from '@modules/features/login/login.route';
import { AppInitService } from '../../app-init.service';
import { MfeSubscriptionCheckoutComponent } from '@modules/shared/components/mfe-subscription-checkout/mfe-subscription-checkout.component';
import { FeaturesActivationComponent } from '@modules/features/feature-activation/features-activation.component';
import { ConnectivityCheckComponent } from 'src/app/components/connectivity-check/connectivity-check.component';

const authenticationRoutes: Routes = [
	{
		path: 'authentication',
		loadChildren: () =>
			import('../authentication/authentication.module').then((m) => m.AuthenticationModule),
	},
];

const invitationRoutes: Routes = [
	{
		path: 'invitations/company/:invitationId',
		component: InvitationsComponent,
	},
];
const externalChatRoutes: Routes = [
	{
		path: 'external-chat',
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
			redirectUnauthorisedTo: '/project',
		},
		canActivate: [AngularFireAuthGuard],
		loadChildren: () =>
			import('./external-chat/external-chat.module').then((m) => m.ExternalChatModule),
	},
];

const scheduleDemoRoutes: Routes = [
	{
		path: 'schedule-demo',
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
			redirectUnauthorisedTo: '/project',
		},
		canActivate: [AngularFireAuthGuard],
		loadChildren: () =>
			import('./schedule-demo/schedule-demo.module').then((m) => m.ScheduleDemoModule),
	},
];

const publicFoldersRoutes: Routes = [
	{
		path: 'publicFolders',
		loadChildren: () =>
			import('./public-folders/public-folders.module').then((m) => m.PublicFoldersModule),
	},
];
const checkoutRoutes: Routes = [
	{
		path: 'checkout',
		component: MfeSubscriptionCheckoutComponent,
		canActivate: [AngularFireAuthGuard, MemberRoleGuard],
		pathMatch: 'full',
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
			redirectUnauthorisedTo: '/project',
			memberRoleGuard: [MemberRole.OWNER],
		},
	},
];

const featuresActivationRoute: Route = {
	path: 'features-activation',
	canActivate: [AngularFireAuthGuard, MemberRoleGuard],
	component: FeaturesActivationComponent,
	pathMatch: 'full',
	data: {
		authGuardPipe: redirectUnauthenticatedToLogin,
		redirectUnauthorisedTo: '/',
		memberRoleGuard: [MemberRole.OWNER],
	},
};

const lazyLoadRoutes: Routes = [
	...authenticationRoutes,
	{
		path: '',
		pathMatch: 'full',
		data: {
			authGuardPipe: redirectLoggedInToDefault,
		},
		canActivate: [AngularFireAuthGuard],
		loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
	},
	{
		path: 'register',
		data: {
			authGuardPipe: redirectLoggedInToDefault,
		},
		pathMatch: 'full',
		canActivate: [AngularFireAuthGuard],
		loadChildren: () => import('./register/register.module').then((m) => m.RegisterModule),
	},
	{
		path: 'password-forgotten',
		data: {
			authGuardPipe: redirectLoggedInToDefault,
		},
		pathMatch: 'full',
		canActivate: [AngularFireAuthGuard],
		loadChildren: () =>
			import('./password-forgotten/password-forgotten.module').then(
				(m) => m.PasswordForgottenModule,
			),
	},
	{
		path: 'dashboard/time-tracking',
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
			redirectUnauthorisedTo: '/project',
		},
		canActivate: [AngularFireAuthGuard],
		loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
		resolve: { companyProjectsLoaded: CompanyProjectsLoadedResolver },
	},
	{
		path: 'tasks-dashboard',
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
			redirectUnauthorisedTo: '/project',
		},
		canActivate: [AngularFireAuthGuard],
		loadChildren: () =>
			import('./tasks-dashboard/tasks-dashboard.module').then((m) => m.TasksDashboardModule),
		resolve: { companyProjectsLoaded: CompanyProjectsLoadedResolver },
	},

	{
		path: 'restorable-projects',
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
			redirectUnauthorisedTo: '/settings',
		},
		canActivate: [AngularFireAuthGuard, OwnerGuard],
		loadChildren: () =>
			import('./restorable-projects/restorable-projects.module').then(
				(m) => m.RestorableProjectsModule,
			),
	},
	{
		path: 'settings',
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
			redirectUnauthorisedTo: '/settings',
		},
		canActivate: [AngularFireAuthGuard],
		loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
	},
	{
		path: 'pdf-viewer',
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
			redirectUnauthorisedTo: '/project',
			profileLimits: [ProfileLimitKey.FILLABLE_FORMS, ProfileLimitKey.SIGNATURE_FORMS],
		},
		canActivate: [AngularFireAuthGuard, ProfileLimitsGuard],
		loadChildren: () => import('./pdf-editor/pdf-editor.module').then((m) => m.PdfEditorModule),
	},
	{
		path: 'planning-board',
		component: CustomWebComponent,
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
			redirectUnauthorisedTo: '/project',
			componentUrl: environment.planning_board_url,
			componentTag: 'planning-board',
		},
		canActivate: [AngularFireAuthGuard],
	},
	{
		path: 'company-init',
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
			redirectUnauthorisedTo: '/project',
			remoteConfigName: 'feature_company_init',
		},
		canActivate: [AngularFireAuthGuard, RemoteConfigGuard],
		loadChildren: () =>
			import('./company-initialization/company-initialization.module').then(
				(m) => m.CompanyInitializationModule,
			),
	},
];

const ROUTES: Routes = [
	{
		path: 'connectivity',
		component: ConnectivityCheckComponent,
		pathMatch: 'full',

	},
	...loginRoute,
	...checkoutRoutes,
	...mobileRoute,
	...lazyLoadRoutes,
	...workRoute,
	...invitationRoutes,
	...externalChatRoutes,
	...scheduleDemoRoutes,
	...publicFoldersRoutes,
	featuresActivationRoute,
	{ path: '**', redirectTo: '/' },
];

const MOBILE_ROUTES: Routes = [
	{
		path: 'connectivity',
		component: ConnectivityCheckComponent,
		pathMatch: 'full',
	},
	...loginRoute,
	...registerRoute,
	...passwordForgottenRoute,
	...authenticationRoutes,
	...externalChatRoutes,
	...publicFoldersRoutes,
	{
		path: 'mobile-page-blocked',
		data: {
			authGuardPipe: redirectUnauthenticatedToLogin,
		},
		canActivate: [AngularFireAuthGuard],
		loadChildren: () =>
			import('./mobile-page-blocked/mobile-page-blocked.module').then(
				(m) => m.MobilePageBlockedModule,
			),
	},
	{ path: '**', redirectTo: 'checkout' },
];

const mobileContainerRoute: Route = {
	data: { mobile: true },
	path: '',
	children: MOBILE_ROUTES,
};

@NgModule({
	imports: [RouterModule.forChild(ROUTES)],
	exports: [RouterModule],
})
export class FeaturesRoutingModule {
	constructor(private readonly router: Router, private readonly appInitService: AppInitService) {
		this.initPlatformChangesSubscription();
	}

	initPlatformChangesSubscription(): void {
		this.appInitService
			.getMobilePlatform()
			.pipe(distinctUntilChanged())
			.subscribe((mobilePlatform) => {
				let routerConfig = [...ROUTES];

				if (mobilePlatform) {
					routerConfig = [mobileContainerRoute];
				}

				this.router.resetConfig(routerConfig);
			});
	}
}
