import { NgModule } from '@angular/core';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { ExportDialogComponent } from './export-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { BytesPipe } from '@modules/shared/pipes/bytes/bytes.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
	declarations: [ExportDialogComponent],
	imports: [
		SharedModule,
		MatIconModule,
		MatButtonModule,
		MatProgressBarModule,
		MatProgressSpinnerModule
	],
	providers: [BytesPipe]
})
export class ExportDialogModule {}
