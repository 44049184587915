import { Export } from 'domain-entities';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectLoggedInAuthState } from '@store/pipes/auth.pipes';
import { switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
	addExports,
	clearExports,
	deleteExports,
	exportStartedAction,
} from '@store/actions/export.actions';
import { FileDownloadService } from '@injectables/services/file-download/file-download.service';
import { BaseFileService } from '@injectables/services/base-file.service';
import { v7 as uuid } from 'uuid';
import moment from 'moment';
import { of } from 'rxjs';
import { cancelExport } from '@shared/firebase/export/export-update.functions';
import { PerformanceTraceService } from '@injectables/services/performance-trace.service';
import { ExportConnector } from '@shared/firebase/connectors/firestore/collections/export/export.connector';
import { selectUserId } from '@store/selectors/app.selectors';

export type CreationExport = Omit<Export, 'id' | 'state' | 'userId' | 'requestTime'>;

@Injectable({
	providedIn: 'root',
})
export class ExportService {
	constructor(
		private readonly exportConnector: ExportConnector,
		private readonly store: Store<AppState>,
		private readonly downloadService: FileDownloadService,
		private readonly fileService: BaseFileService,
		private readonly performanceTraceService: PerformanceTraceService,
	) {
		this.watchExports();
	}

	async createExport(creationExport: CreationExport): Promise<Export> {
		const exportEntity = await this.createExportInternal(creationExport);
		this.store.dispatch(exportStartedAction({ export: exportEntity }));
		await this.exportConnector.createExports(exportEntity);
		return exportEntity;
	}

	async downloadExport(exportEntity: Export): Promise<void> {
		const fileName: string = this.fileService.getFilenameWithExtensionFromPath(
			exportEntity.location,
		);
		this.performanceTraceService.stop(exportEntity.id);
		await this.downloadService.downloadExportFile(exportEntity.location, fileName);
	}

	private async createExportInternal(partialExportEntity: CreationExport): Promise<Export> {
		const user = await this.store.pipe(selectLoggedInAuthState, take(1)).toPromise();

		const exportEntity: Export = {
			...partialExportEntity,
			id: uuid(),
			userId: user.userId,
			state: 'requested',
			requestTime: moment().unix(),
		};
		await this.exportConnector.createExports(exportEntity);
		return exportEntity;
	}

	watchExports(): void {
		const userId$ = this.store.select(selectUserId);
		userId$
			.pipe(
				switchMap((userId) => {
					if (!userId) {
						this.store.dispatch(clearExports());
						return of(null);
					}
					return this.exportConnector.watchExports(userId);
				}),
			)
			.subscribe((changes) => {
				if (!changes) {
					return;
				}
				if (changes.changeType === 'created' || changes.changeType === 'updated') {
					this.store.dispatch(addExports({ exports: changes.entities }));
				} else if (changes.changeType === 'deleted') {
					this.store.dispatch(deleteExports({ ids: changes.entities.map((entity) => entity.id) }));
				}
			});
	}

	cancelExport(idOfExportToCancel): void {
		this.exportConnector.updateExport(idOfExportToCancel, cancelExport);
	}
}
