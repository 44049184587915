<div class="user-deactivated-dialog mat-typography">
	<h1 mat-dialog-title class="mat-display-1" [innerHTML]="'toc.dialog.userDeactivated.title' | translate"></h1>
	<div mat-dialog-content class="text-muted">
		<p class="mb-3">{{ 'toc.dialog.userDeactivated.deactivationText' | translate }}</p>
		
		<ng-container *ngIf="isOwner$ | async; else nonOwner">
			<p class="mb-3">{{ 'toc.dialog.userDeactivated.ownerReactivationText' | translate }}</p>
			<p class="mb-3">{{ 'toc.dialog.userDeactivated.supportText' | translate }}</p>

			<form [formGroup]="acceptanceForm" class="mt-4">
				<div class="d-flex flex-column">
					<mat-checkbox formControlName="tocAccepted" class="mb-2">
						<strong>
							{{ 'register.agreed' | translate }}
							<a href="{{ linkService.tocLink }}" target="_blank" rel="noopener" class="link">
								{{ 'toc.agb' | translate }}
							</a>
						</strong>
					</mat-checkbox>
					
					<mat-checkbox formControlName="agbAccepted">
						<strong>
							{{ 'register.agreed' | translate }}
							<a href="{{ linkService.legalNoticeLink }}" target="_blank" rel="noopener" class="link">
								{{ 'toc.datenschutz' | translate }}
							</a>
						</strong>
					</mat-checkbox>
				</div>
			</form>
		</ng-container>
		<ng-template #nonOwner>
			<p class="mb-3">{{ 'toc.dialog.userDeactivated.nonOwnerText' | translate }}</p>
			<p>{{ 'toc.dialog.userDeactivated.supportText' | translate }}</p>
		</ng-template>
	</div>
	<div mat-dialog-actions class="justify-content-end">
		<button mat-stroked-button (click)="cancel()" class="mr-2" *ngIf="isOwner$ | async">
			{{ 'toc.dialog.declineText' | translate }}
		</button>
		<button mat-stroked-button (click)="cancel()" class="mr-2" *ngIf="!(isOwner$ | async)">
			{{ 'button.accept' | translate }}
		</button>
		<button mat-flat-button color="accent" *ngIf="isOwner$ | async" [disabled]="!acceptanceForm.valid || isAccepting" (click)="acceptTerms()">
			<div class="d-flex align-items-center justify-content-center">
				<mat-spinner *ngIf="isAccepting" [diameter]="20" class="mr-2"></mat-spinner>
				<span>{{ 'button.accept' | translate }}</span>
			</div>
		</button>
	</div>
</div>
