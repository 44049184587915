<div class="export-dialog d-flex flex-column h-100 p-3 overflow-hidden">
	<p class="mat-display-2 mb-3">{{ 'exportDetailsModal.title' | translate }}</p>
	<div class="flex-grow-1 rounded-small border overflow-y-auto">
		<div *ngFor="let exportEntity of exports">
			<ng-container *ngTemplateOutlet="exportRow; context: { $implicit: exportEntity }"></ng-container>
		</div>
	</div>
	<div class="d-flex mt-4 justify-content-end">
		<button (click)="onClose()" mat-stroked-button>
			{{ 'exportDetailsModal.hide' | translate }}
		</button>
	</div>
</div>

<ng-template #exportRow let-exportEntity>
	<div class="d-flex flex-column p-2 border export-row" 
		 [ngClass]="{ 'failed': isFailed(exportEntity) }"
		 [style.--progress]="getProgressValue(exportEntity) + '%'">
		<div class="d-flex justify-content-between align-items-center">
			<div class="d-flex align-items-center flex-grow-1">
				<div class="export-dialog__status-indicator d-flex justify-content-center align-items-center px-2">
					<ng-container>
						<ng-container *ngTemplateOutlet="!isFailed(exportEntity) ? progress : failed; context: { $implicit: exportEntity }"></ng-container>
					</ng-container>
				</div>
				<div class="flex-grow-1">
					<span class="d-block mat-body-2">{{
						exportEntity.fileName ? exportEntity.fileName : ('exportDetailsModal.preparingName' | translate)
					}}</span>
					<span
						class="d-block export-dialog__status mat-small"
						[ngClass]="{ 'export-dialog__status__failed': isFailed(exportEntity) }"
						data-cy="export-preparing"
					>
						<ng-container *ngIf="isFailed(exportEntity); else preparingStatus">
							{{ 'exportDetailsModal.failed' | translate }}
						</ng-container>
						<ng-template #preparingStatus>
							<ng-container *ngIf="exportEntity.storageData; else defaultPreparing">
								{{ 'exportDetailsModal.preparing' | translate }} - 
								{{ formatBytes(exportEntity.storageData.bytes) }} - 
								{{ getEstimatedTimeText(exportEntity.storageData.estimatedExportTimeSeconds) }}
							</ng-container>
							<ng-template #defaultPreparing>
								{{ 'exportDetailsModal.preparing' | translate }}
							</ng-template>
						</ng-template>
					</span>
				</div>
				<mat-icon (click)="cancelExport.emit(exportEntity.id)" class="pointer">close</mat-icon>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #progress let-exportEntity>
	<div class="progress-container">
		<span class="progress-value">{{ getProgressValue(exportEntity) }}%</span>
		<mat-spinner 
			*ngIf="!hasDownloadedFiles(exportEntity)" 
			[diameter]="48" 
			[strokeWidth]="2"
			[style.width.px]="48"
			[style.height.px]="48">
		</mat-spinner>
	</div>
</ng-template>

<ng-template #failed>
	<mat-icon>error_outline</mat-icon>
</ng-template>
