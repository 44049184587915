import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Export } from 'domain-entities';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-export-snackbar',
	templateUrl: './export-snackbar.component.html',
	styleUrls: ['./export-snackbar.component.scss'],
})
export class ExportSnackbarComponent {
	constructor(private readonly translationService: TranslateService) {}

	@Input() hasRunningExports: boolean;
	@Input() hasFailedExports: boolean;
	@Input() runningExports: Export[] = [];
	@Input() failedExports: Export[] = [];
	@Input() isDetailsDialogOpen = false;

	@Output() complete = new EventEmitter<void>();
	@Output() openDetails = new EventEmitter<void>();

	get hasOnlyFailed(): boolean {
		return !!this.hasFailedExports && !this.hasRunningExports;
	}

	get hasDownloadedFiles(): boolean {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return this.runningExports.some((exp: any) => exp.storageData?.downloadedFiles > 0);
	}

	onOpenDetails(): void {
		this.openDetails.emit();
	}

	get totalProgress(): number {
		if (!this.runningExports.length) return 0;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const totalFiles = this.runningExports.reduce((sum, exp: any) => 
			sum + (exp.storageData?.files || 0), 0);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const downloadedFiles = this.runningExports.reduce((sum, exp: any) => 
			sum + (exp.storageData?.downloadedFiles || 0), 0);

		if (!totalFiles) return 0;
		return Math.round((downloadedFiles / totalFiles) * 100);
	}

	get exportText(): string {
		let text = '';
		if (this.hasRunningExports) {
			const progress = this.totalProgress;
			text += this.translationService.instant('exportToast.info', {
				numberOfRunningExports: this.runningExports.length,
				progress: progress && this.hasDownloadedFiles ? ` (${progress}%)` : '',
			});
		}
		if (this.hasFailedExports) {
			if (this.hasRunningExports) {
				text += '; ';
			}
			text += this.translationService.instant('exportToast.failed', {
				numberOfFailedExports: this.failedExports.length,
			});
		}
		return text;
	}
}
