import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthService } from '@injectables/services/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '@injectables/services/profile/profile.service';
import { LinkService } from '@injectables/services/link/link.service';
import moment from 'moment';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { selectUserId, isOwnerOfCompany } from '@store/selectors/app.selectors';
import { take } from 'rxjs/operators';
import { updateProfileToc } from '@shared/firebase/profile/profile-update.functions';
import { Observable } from 'rxjs';
import { ProjectHelperService } from '@injectables/services/project-helper.service';
import { setAppInitState } from '@store/actions/app-init.actions';
import { AppInitStatus } from '@store/state/app-init.state';

@Component({
	selector: 'app-user-deactivated-dialog',
	templateUrl: './user-deactivated-dialog.component.html',
	styleUrls: ['./user-deactivated-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class UserDeactivatedDialogComponent {
	isOwner$: Observable<boolean> = this.store.select(isOwnerOfCompany);
	acceptanceForm: FormGroup;
	isAccepting = false;

	constructor(
		private readonly dialogRef: MatDialogRef<UserDeactivatedDialogComponent>,
		private readonly authService: AuthService,
		private readonly store: Store<AppState>,
		private readonly formBuilder: FormBuilder,
		private readonly profileService: ProfileService,
		private readonly remoteConfig: RemoteConfig,
		private readonly projectHelperService: ProjectHelperService,
		public readonly linkService: LinkService
	) {
		this.acceptanceForm = this.formBuilder.group({
			tocAccepted: [false, Validators.requiredTrue],
			agbAccepted: [false, Validators.requiredTrue]
		});
	}

	async acceptTerms(): Promise<void> {
		if (this.acceptanceForm.valid && !this.isAccepting) {
			try {
				this.isAccepting = true;
				const userId = await this.store.select(selectUserId).pipe(take(1)).toPromise();
				const version = Number(await this.remoteConfig.getValueAsync('version_current_toc'));
				
				// Update ToC acceptance
				await this.profileService.updateProfileTransactional(
					userId,
					updateProfileToc('accepted', moment().unix(), version),
				);
				
				// Mark app initialization as complete
				this.store.dispatch(setAppInitState({ state: AppInitStatus.DONE }));
				
				this.dialogRef.close();
				
				// Navigate to projects using the project helper service
				await this.projectHelperService.goToHome();
			} finally {
				this.isAccepting = false;
			}
		}
	}

	async cancel(): Promise<void> {
		await this.authService.signOut();
		this.dialogRef.close();
	}
}
