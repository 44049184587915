import { SharedModule } from '@craftnote/shared-angular-modules';
import { NgModule } from '@angular/core';
import { NotificationSnackbarComponent } from './notification-snackbar.component';
import { BasicSnackbarComponent } from './basic-snackbar/basic-snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { ExportSnackbarComponent } from './export-snackbar/export-snackbar.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ExportSnackbarWrapperComponent } from './export-snackbar/export-snackbar-wrapper/export-snackbar-wrapper.component';
import { ExportDialogModule } from '../../dialog/components/export-dialog/export-dialog.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
	declarations: [
		NotificationSnackbarComponent,
		BasicSnackbarComponent,
		ExportSnackbarComponent,
		ExportSnackbarWrapperComponent,
	],
	imports: [SharedModule, MatIconModule, MatButtonModule, ExportDialogModule, MatProgressBarModule],
	exports: [NotificationSnackbarComponent, BasicSnackbarComponent],
})
export class NotificationSnackbarModule {}
