import { Component, EventEmitter, Output } from '@angular/core';
import { Export } from 'domain-entities';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { isFailed } from '@shared/firebase/export/export.functions';
import { BytesPipe } from '@modules/shared/pipes/bytes/bytes.pipe';

@Component({
	selector: 'app-export-dialog',
	templateUrl: './export-dialog.component.html',
	styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent {
	@Output() cancelExport = new EventEmitter<string>();

	isFailed = isFailed;
	data: { exports: Export[] };
	private maxProgressValues = new Map<string, number>();

	get exports(): Export[] {
		return this.data?.exports || [];
	}

	constructor(
		private readonly ref: MatDialogRef<ExportDialogComponent>,
		private readonly bytesPipe: BytesPipe
	) {}

	onClose(): void {
		this.ref.close();
	}

	getEstimatedTimeText(seconds: number): string {
		if (!seconds) return '';
		
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		
		if (minutes === 0) {
			return `~${remainingSeconds}s`;
		} else if (remainingSeconds === 0) {
			return `~${minutes}m`;
		} else {
			return `~${minutes}m ${remainingSeconds}s`;
		}
	}

	formatBytes(bytes: number): string {
		return this.bytesPipe.transform(bytes);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	hasDownloadedFiles(exportEntity: any): boolean {
		return exportEntity?.storageData?.downloadedFiles > 0;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getProgressValue(exportEntity: any): number {
		if (!exportEntity?.storageData?.files || !exportEntity?.storageData?.downloadedFiles) {
			return 0;
		}
		
		const currentProgress = Math.round((exportEntity.storageData.downloadedFiles / exportEntity.storageData.files) * 100);
		const boundedProgress = Math.min(Math.max(currentProgress, 0), 100);
		
		// Use the export ID as the key for tracking max progress
		const exportId = exportEntity.id;
		const previousMax = this.maxProgressValues.get(exportId) || 0;
		const newProgress = Math.max(boundedProgress, previousMax);
		
		// Store the new maximum
		this.maxProgressValues.set(exportId, newProgress);
		
		return newProgress;
	}
}
