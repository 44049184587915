import { Component, OnDestroy } from '@angular/core';
import { Export } from 'domain-entities';
import { Store } from '@ngrx/store';
import { selectExports } from '@store/selectors/export.selectors';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ExportDialogComponent } from '../../../../dialog/components/export-dialog/export-dialog.component';
import { Observable, Subject } from 'rxjs';
import { ExportService } from '@injectables/export/export.service';
import { takeUntil, tap } from 'rxjs/operators';
import { isFailed, isRunning } from '@shared/firebase/export/export.functions';
import { closeExportModalAction } from '@store/actions/export.actions';

@Component({
	selector: 'app-export-snackbar-wrapper',
	templateUrl: './export-snackbar-wrapper.component.html',
	styleUrls: ['./export-snackbar-wrapper.component.scss'],
})
export class ExportSnackbarWrapperComponent implements OnDestroy {
	private detailsDialogRef: MatDialogRef<ExportDialogComponent>;
	private destroy$ = new Subject();

	exports$: Observable<Export[]> = this.store.select(selectExports);

	runningExports: Export[] = [];
	failedExports: Export[] = [];

	get hasRunningExports(): boolean {
		return !!this.runningExports.length;
	}

	get hasFailedExports(): boolean {
		return !!this.failedExports.length;
	}

	get isDialogOpen(): boolean {
		return !!this.detailsDialogRef;
	}

	get allRelevantExports(): Export[] {
		return [...this.runningExports, ...this.failedExports];
	}

	constructor(
		private readonly store: Store,
		private readonly exportService: ExportService,
		private readonly dialog: MatDialog,
	) {
		this.initExports();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	onComplete(): void {
		this.store.dispatch(closeExportModalAction());
		this.detailsDialogRef?.close();
	}

	async onOpenDetails(): Promise<void> {
		this.detailsDialogRef = this.dialog.open(ExportDialogComponent, {
			width: '600px',
			height: '600px',
			autoFocus: false,
		});

		this.setDialogData();

		const cancellationSubscription = this.detailsDialogRef.componentInstance.cancelExport.subscribe(
			(idOfExportToCancel) => {
				if (this.runningExports.find((exportEntity) => exportEntity.id === idOfExportToCancel)) {
					this.exportService.cancelExport(idOfExportToCancel);
				} else {
					this.failedExports = this.failedExports.filter(
						(exportEntity) => exportEntity.id !== idOfExportToCancel,
					);
					this.setDialogData();
				}
			},
		);
		await this.detailsDialogRef
			.afterClosed()
			.pipe(
				tap(() => {
					cancellationSubscription.unsubscribe();
				}),
			)
			.toPromise();

		this.detailsDialogRef = undefined;
	}

	private initExports(): void {
		this.exports$.pipe(takeUntil(this.destroy$)).subscribe((exportEntity) => {
			const totalFailedExports = exportEntity.filter(isFailed);

			totalFailedExports.forEach((failedExport) => {
				// We only care about those failed exports which have been running during the lifetime of this component
				if (this.runningExports.some((runningExport) => runningExport.id === failedExport.id)) {
					this.failedExports.push(failedExport);
				}
			});
			this.runningExports = exportEntity
				.filter(isRunning)
				.sort((a, b) => a.requestTime - b.requestTime);
			this.setDialogData();
		});
	}

	private setDialogData(): void {
		if (this.detailsDialogRef?.componentInstance) {
			this.detailsDialogRef.componentInstance.data = { exports: this.allRelevantExports };
		}
		if (!this.runningExports.length && !this.failedExports.length) {
			this.onComplete();
		}
	}
}
