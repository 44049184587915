<div *ngIf="!isDetailsDialogOpen" class="export_snackbar__container d-flex flex-column rounded-small">
	<div class="d-flex pl-2 justify-content-between align-items-center flex-grow-1">
		<div class="d-flex align-items-center export_snackbar__container__text-content">
			<mat-icon>download</mat-icon>
			<p class="pl-2 font-weight-bold m-0">{{ exportText }}</p>
		</div>
		<div class="d-flex flex-shrink-0">
			<button *ngIf="!hasRunningExports" (click)="complete.emit()" mat-button>
				{{ 'exportToast.close' | translate }}
			</button>
			<button 
				(click)="onOpenDetails()" 
				mat-button 
				class="px-2" 
				data-cy="open-view-export-details">
				{{ 'exportToast.details' | translate }}
			</button>
		</div>
	</div>
	<div
		class="export_snackbar__loader"
		[ngClass]="{
			export_snackbar__loader__failed: hasOnlyFailed
		}"
	>
		<mat-progress-bar
			*ngIf="hasRunningExports && !hasDownloadedFiles"
			mode="indeterminate"
			color="accent"
		></mat-progress-bar>
		<mat-progress-bar
			*ngIf="hasRunningExports && hasDownloadedFiles"
			[value]="totalProgress"
			mode="determinate"
			color="accent"
		></mat-progress-bar>
	</div>
</div>
